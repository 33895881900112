import React, { useState } from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'

const OpeningChant = () => {
  const [translated, setTranslated] = useState(false)

  const Content = styled.div`
    ${tw`text-center`}
  `

  const Body = styled.h1`
    ${tw``}

    max-width: 800px;

    p {
      ${tw`mb-8`}
    }
  `

  const English = styled.div`
    ${tw``}

    ${translated && tw`hidden`}
  `

  const Sanskrit = styled.div`
    ${tw``}

    ${!translated && tw`hidden`}
  `

  const Button = styled.button`
    ${tw`p-4 absolute bottom-0 mb-10 font-medium font-display tracking-widest uppercase`}
  `

  const handleToggle = () => {
    setTranslated(!translated)
  }

  return (
    <>
      <Content>
        <Body>
          <English>
            <p>OM</p>
            <p>...</p>
            <p>
              Vande Gurunam Charanaravinde <br />
              Sandarshita Svatma Sukava Bodhe <br />
              Nih Sreyase Jangalikayamane <br />
              Samsara Halahala Mohashantyai
            </p>
            <p>
              Abahu Purushakaram <br />
              Shankhacakrsi Dharinam <br />
              Sahasra Sirasam Svetam <br />
              Pranamami Patanjalim
            </p>
            <p>...</p>
            <p>OM</p>
          </English>
          <Sanskrit>
            <p>OM</p>
            <p>...</p>
            <p>
              I bow to the lotus feet of the Supreme Guru which awaken insight
              into the happiness of pure Being, which are the refuge, the jungle
              physician, which eliminate the delusion caused by the poisonous
              herb of Samsara.
            </p>
            <p>
              I prostrate before the sage Patanjali who has thousands of
              radiant, white heads and who has, as far as his arms, assumed the
              form of a man holding a conch shell, a wheel and a sword.
            </p>
            <p>...</p>
            <p>OM</p>
          </Sanskrit>
        </Body>
      </Content>
      <Button onClick={() => handleToggle(translated)}>Translate</Button>
    </>
  )
}

export default OpeningChant
