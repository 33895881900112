import React from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Link } from 'react-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import OpeningChant from '../components/OpeningChant'
import { breakpoints } from '../utils/breakpoints'

const Wrap = styled.div`
  ${tw`flex items-center justify-center px-10 py-20 relative`}
  height: 100vh;
  min-height: 500px;
`

const Content = styled.div`
  ${tw`text-center`}
`

const Title = styled.h1`
  ${tw`py-20 mb-40
  font-display font-medium uppercase leading-none tracking-wide`}
  font-size: 8vw;
  @media (min-width: ${breakpoints.md}px) {
    ${tw`mb-0`}
    font-size: 5vw;
  }
`

const Body = styled.h1`
  ${tw``}

  max-width: 800px;

  p {
    ${tw`mb-8`}
  }
`

const Button = styled.div`
  ${tw`p-4 absolute bottom-0 mb-40 font-medium font-display tracking-widest uppercase cursor-pointer`}
  @media (min-width: ${breakpoints.md}px) {
    ${tw`mb-20`}
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="Namaste" />
    <Wrap id="home">
      <Content>
        <Title>
          Ashtanga Yoga Canggu
          <br />
          *Mysore style
        </Title>
      </Content>
      <Button>
        <Link to="namaste" smooth duration={1000}>
          <FontAwesomeIcon icon={['fal', 'long-arrow-down']} size="3x" />
        </Link>
      </Button>
    </Wrap>
    <Wrap id="namaste">
      <Content>
        <Body>
          <p>
            We’re a studio independent group of practitioners of Ashtanga
            Vinyasa Yoga, Mysore style, in Canggu, Bali.
          </p>

          <p>
            We want to gather practioners and provide information to newcomers
            and ensure that there's always a space for Mysore practice in
            Canggu. Currently we practice at{' '}
            <a href="https://www.samadibali.com/">Samadi Yoga</a>.
          </p>

          <p>
            Anyone is welcome to join our group, beginners and advanced. Our
            teachers have KPJAYI certification.
          </p>
          <p>Namaste.</p>
        </Body>
      </Content>
    </Wrap>
    <Wrap id="registration">
      <Content>
        <Body>
          <p>Registration will open soon</p>
        </Body>
      </Content>
    </Wrap>
    <Wrap id="chant">
      <OpeningChant />
    </Wrap>
  </Layout>
)

export default IndexPage
